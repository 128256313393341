import React from 'react'
import parse from 'html-react-parser'

import PostList from '../components/Post/PostList'
import PostPagination from '../components/Post/PostPagination'

const ArchivePage = ({ location, archivePath, pageInfo, pageData, categories, posts, allPostsLength }) => {
    const {
        postsPage: { postsDescription },
    } = pageData

    return (
        <div className='global-container archive__inner'>
            <h1 className='global-title-h1'>{pageData.title}</h1>
            {postsDescription && <p className='global-text'>{parse(postsDescription)}</p>}
            <PostList posts={posts} categories={categories} allPostsLength={allPostsLength} />
            <PostPagination {...pageInfo} archivePath={archivePath} />
        </div>
    )
}

export default ArchivePage

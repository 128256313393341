import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import ArchivePage from '../ArchivePage'

const archive = props => {
    const {
        data: {
            allPosts: { edges },
            filteredPosts: { nodes, pageInfo },
            allWpPage,
            allWpTermNode,
        },
        pageContext: { archiveType, archivePath, uri },
        location,
    } = props
    const pageData = allWpPage.nodes[0]
    const categoriesData = allWpTermNode.nodes
    const { title } = pageData.seo

    console.log('...ARCHIVE data start...')
    console.log(props)
    console.log('...ARCHIVE data end...')

    return (
        <Layout bodyClass='archive' pageUri={location.pathname}>
            <Seo title={title ? title : pageData.title} meta={pageData.seo} uri={uri} />

            <ArchivePage
                location={location}
                archivePath={archivePath}
                pageInfo={pageInfo}
                pageData={pageData}
                categories={categoriesData}
                posts={nodes}
                allPostsLength={edges.length}
            />
        </Layout>
    )
}

export const query = graphql`
    query archive($offset: Int!, $perPage: Int!, $userDatabaseId: Int, $categoryDatabaseId: Int) {
        filteredPosts: allWpPost(
            limit: $perPage
            skip: $offset
            filter: {
                author: { node: { databaseId: { eq: $userDatabaseId } } }
                categories: { nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } } }
            }
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                ...PostPreviewContent
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                currentPage
                pageCount
            }
        }
        allPosts: allWpPost(sort: { fields: date, order: DESC }) {
            edges {
                node {
                    id
                }
            }
        }
        allWpPage(filter: { isPostsPage: { eq: true } }) {
            nodes {
                id
                title
                uri
                postsPage {
                    postsDescription
                }
                seo {
                    title
                    metaDesc
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            ...PostThumbnail
                        }
                    }
                }
            }
        }
        allWpTermNode {
            nodes {
                ... on WpCategory {
                    databaseId
                    id
                    name
                    uri
                    count
                }
            }
        }
    }
`

export default archive

// extracted by mini-css-extract-plugin
export var list = "post-module--list--t0k+b";
export var list__column = "post-module--list__column--O-dzr";
export var list__item = "post-module--list__item--q7UAx";
export var list__item__featured = "post-module--list__item__featured--Mi0QS post-module--list__item--q7UAx";
export var postTitle = "post-module--postTitle--YZYix";
export var postTitleMore = "post-module--postTitleMore--adVWC";
export var categories = "post-module--categories--C2Ev1";
export var categoriesList = "post-module--categoriesList--hdK-V";
export var categoriesTag = "post-module--categoriesTag---j6pS";
export var widthIn = "post-module--widthIn--Nmicg";
export var categoriesActive = "post-module--categoriesActive--5l2nl";
export var categoriesCount = "post-module--categoriesCount--rY4-O";
export var post = "post-module--post--Gk7Mk";
export var postImage = "post-module--postImage--5EAoC";
export var postDate = "post-module--postDate--BMQkI";
export var postDescription = "post-module--postDescription--rFl51";
export var pagination = "post-module--pagination--xWLFi";
export var paginationNav = "post-module--paginationNav--Mo1Gc";
export var paginationDots = "post-module--paginationDots--x6jnq";
export var paginationLink = "post-module--paginationLink--975Pv";
export var paginationArrowLink = "post-module--paginationArrowLink--JcbLH";
export var paginationActiveLink = "post-module--paginationActiveLink--MpmmW";
export var paginationDisabledLink = "post-module--paginationDisabledLink--xPLzj";
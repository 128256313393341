import React from 'react'
import { Link } from 'gatsby'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

import * as styles from './post.module.scss'

const renderPreviousLink = ({ hasPreviousPage, currentPage, archivePath }) => {
    let previousLink = null

    if (2 === currentPage) {
        previousLink = archivePath
    } else if (2 < currentPage) {
        previousLink = `${archivePath}page/${currentPage - 1}`
    }

    if (hasPreviousPage) {
        return (
            <Link className={styles.paginationArrowLink} to={previousLink}>
                <span className='visually-hidden'>Newer Posts</span>
                <LeftOutlined />
            </Link>
        )
    } else {
        return (
            <span className={styles.paginationDisabledLink} aria-hidden='true'>
                <span className='visually-hidden'>Newer Posts</span>
                <LeftOutlined />
            </span>
        )
    }
}

const renderNextLink = ({ hasNextPage, currentPage, archivePath }) => {
    if (hasNextPage) {
        return (
            <Link className={styles.paginationArrowLink} to={`${archivePath}page/${currentPage + 1}`}>
                <span className='visually-hidden'>Older Posts</span>
                <RightOutlined />
            </Link>
        )
    } else {
        return (
            <span className={styles.paginationDisabledLink} aria-hidden='true'>
                <span className='visually-hidden'>Older Posts</span>
                <RightOutlined />
            </span>
        )
    }
}

const renderPagesInBetween = ({ currentPage, pageCount, archivePath }) => {
    const pageNumber = (page, isCurrent, isFirst) => {
        if (isCurrent) {
            return {
                tag: 'span',
                children: page,
                // className: 'page-numbers',
                className: styles.paginationActiveLink,
            }
        }

        const to = isFirst ? archivePath : `${archivePath}page/${page}`

        return {
            tag: Link,
            children: page,
            to: to,
            className: styles.paginationLink,
        }
    }

    const dots = {
        tag: 'span',
        children: '…',
        // className: 'page-numbers dots',
        className: styles.paginationDots,
    }

    let elementsToRender = []

    if (pageCount > 5) {
        // current is 1 - 3: show all on left side and dots for right
        if (currentPage < 4) {
            for (let i = 1; i <= currentPage + 1; i++) {
                elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
            }
            elementsToRender.push(dots) // dots in between
            elementsToRender.push(pageNumber(pageCount, false, false)) // last page
        }
        // if on of the last 3
        else if (currentPage >= pageCount - 2) {
            elementsToRender.push(pageNumber(1, false, true)) // last page
            elementsToRender.push(dots)

            for (let i = currentPage - 1; i < pageCount + 1; i++) {
                elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
            }
        } else {
            elementsToRender.push(pageNumber(1, false, true)) // last page
            elementsToRender.push(dots)

            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
            }

            elementsToRender.push(dots)
            elementsToRender.push(pageNumber(pageCount, false, false)) // last page
        }
    } else {
        for (let i = 1; i < pageCount + 1; i++) {
            elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
        }
    }

    return elementsToRender.map(({ tag: Tag, children, ...props }, index) => (
        <Tag key={index} {...props}>
            {children}
        </Tag>
    ))
}

const PostPagination = ({ hasPreviousPage, hasNextPage, currentPage, pageCount, archivePath }) => {
    if (pageCount <= 1) {
        return null
    }

    return (
        <div className={styles.pagination}>
            <nav className={styles.paginationNav} role='navigation' aria-label='Posts'>
                <h2 className='visually-hidden'>Posts navigation</h2>
                {renderPreviousLink({ hasPreviousPage, currentPage, archivePath })}
                {renderPagesInBetween({ currentPage, pageCount, archivePath })}
                {renderNextLink({ hasNextPage, currentPage, archivePath })}
            </nav>
        </div>
    )
}

export default PostPagination

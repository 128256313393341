import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import PostCategories from './PostCategories'
import * as styles from './post.module.scss'
import ArrowRightIcon from '../../assets/svg/arrow-right.inline.svg'

const PostList = ({ posts, categories, allPostsLength }) => {
    const [entities, setEntities] = useState([])

    useEffect(() => {
        posts.forEach((item, index) => {
            index === 2
                ? setEntities(prevArray => {
                      prevArray[prevArray.length - 1].push(item)

                      return prevArray
                  })
                : setEntities(prevArray => {
                      return [...prevArray, [item]]
                  })
        })
    }, [])

    return (
        <>
            <PostCategories categories={categories} allPostsLength={allPostsLength} />
            {entities.length > 0 && (
                <ul className={styles.list}>
                    {entities.map((item, index) => {
                        const post = item[0]
                        const heroImage =
                            post.heroSection && post.heroSection.heroSectionRightSide.heroSectionImage
                                ? getImage(post.heroSection.heroSectionRightSide.heroSectionImage.localFile)
                                : null
                        const image =
                            post.featuredImage && post.featuredImage.node
                                ? getImage(post.featuredImage.node.localFile)
                                : null

                        return (
                            <li className={index === 0 ? styles.list__item__featured : styles.list__item} key={post.id}>
                                {index === 1 ? (
                                    <ul className={styles.list__column}>
                                        {item.map(post => {
                                            const image =
                                                post.featuredImage && post.featuredImage.node
                                                    ? getImage(post.featuredImage.node.localFile)
                                                    : null

                                            return (
                                                <li className={styles.list__item} key={post.id}>
                                                    <article
                                                        itemScope
                                                        itemType='http://schema.org/Article'
                                                        className={styles.post}
                                                    >
                                                        <Link to={post.uri} itemProp='url'>
                                                            {image && (
                                                                <div className={styles.postImageWrapper}>
                                                                    <GatsbyImage
                                                                        className={styles.postImage}
                                                                        image={image}
                                                                        alt={
                                                                            post.featuredImage.alt
                                                                                ? post.featuredImage.alt
                                                                                : 'Post Preview image'
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <small className={styles.postDate}>{post.date}</small>
                                                            <h3 className={styles.postTitle} itemProp='headline'>
                                                                {post.title}
                                                            </h3>
                                                            {post.excerpt && (
                                                                <div
                                                                    itemProp='description'
                                                                    className={styles.postDescription}
                                                                >
                                                                    {parse(post.excerpt)}
                                                                </div>
                                                            )}
                                                        </Link>
                                                    </article>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                ) : (
                                    <article itemScope itemType='http://schema.org/Article' className={styles.post}>
                                        <Link to={post.uri} itemProp='url'>
                                            {heroImage && index === 0 ? (
                                                <div className={styles.postImageWrapper}>
                                                    <GatsbyImage
                                                        className={styles.postImage}
                                                        image={heroImage}
                                                        alt={
                                                            post.featuredImage.alt
                                                                ? post.featuredImage.alt
                                                                : 'Post Preview image'
                                                        }
                                                    />
                                                </div>
                                            ) : image ? (
                                                <div className={styles.postImageWrapper}>
                                                    <GatsbyImage
                                                        className={styles.postImage}
                                                        image={image}
                                                        alt={
                                                            post.featuredImage.alt
                                                                ? post.featuredImage.alt
                                                                : 'Post Preview image'
                                                        }
                                                    />
                                                </div>
                                            ) : null}
                                            <small className={styles.postDate}>{post.date}</small>
                                            <h3 className={styles.postTitle} itemProp='headline'>
                                                {post.title}
                                            </h3>
                                            {post.excerpt && (
                                                <div itemProp='description' className={styles.postDescription}>
                                                    {parse(post.excerpt)}
                                                </div>
                                            )}
                                            <span className={styles.postTitleMore}>
                                                <span>Подробнее</span>
                                                <ArrowRightIcon />
                                            </span>
                                        </Link>
                                    </article>
                                )}
                            </li>
                        )
                    })}
                </ul>
            )}
        </>
    )
}

export default PostList

import React from 'react'
import { Link } from 'gatsby'

import * as styles from './post.module.scss'

const PostCategories = ({ categories, allPostsLength }) => {
    return (
        <div className={styles.categories}>
            {categories.length > 0 && (
                <ul className={styles.categoriesList}>
                    <li key='all'>
                        <Link to='/blog/'>
                            <span className='visually-hidden'>Content with the category of </span>
                            <span className={styles.categoriesTag}>Все</span>
                            <span className={styles.categoriesCount}>
                                <span className='visually-hidden'> and the number of posts </span>({allPostsLength})
                            </span>
                        </Link>
                    </li>
                    {categories.map(cat => {
                        return cat.count ? (
                            <li key={cat.id}>
                                <Link
                                    to={cat.uri.includes('/wp/') ? cat.uri.replace('/wp/', '/') : cat.uri}
                                    partiallyActive={true}
                                    activeClassName={styles.categoriesActive}
                                >
                                    <span className='visually-hidden'>Content with the category of </span>
                                    <span className={styles.categoriesTag}>{cat.name}</span>
                                    <span className={styles.categoriesCount}>
                                        <span className='visually-hidden'> and the number of posts </span>({cat.count})
                                    </span>
                                </Link>
                            </li>
                        ) : null
                    })}
                </ul>
            )}
        </div>
    )
}

export default PostCategories
